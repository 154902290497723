import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "create-a-source",
      "style": {
        "position": "relative"
      }
    }}>{`Create a Source`}<a parentName="h2" {...{
        "href": "#create-a-source",
        "aria-label": "create a source permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`You may create an unlimited number of Sources of any type. Creating a Source starts with choosing a type and setting a
name. Configuring a Source depends on the Source type's available options.`}</p>
    <ol>
      <li parentName="ol">{`Click on Sources in the navigation sidebar`}</li>
      <li parentName="ol">{`Click the "Add Source" button`}</li>
      <li parentName="ol">{`Select the type of source you'd like to configure.    `}</li>
      <li parentName="ol">{`Provide a name for the Source`}</li>
      <li parentName="ol">{`Click "Create Source"`}</li>
    </ol>
    <p>{`Sources are available to collect Events immediately. `}</p>
    <h2 {...{
      "id": "update-a-source",
      "style": {
        "position": "relative"
      }
    }}>{`Update a Source`}<a parentName="h2" {...{
        "href": "#update-a-source",
        "aria-label": "update a source permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`You may update any Source at any time. A Source's available options are dependent on the Source type.`}</p>
    <ol>
      <li parentName="ol">{`Click on Sources in the navigation sidebar`}</li>
      <li parentName="ol">{`Click the name of the Source you'd like to update`}</li>
      <li parentName="ol">{`Select the Settings tab`}</li>
      <li parentName="ol">{`Modify the fields of your choice and click Save Source to finish`}</li>
    </ol>
    <h2 {...{
      "id": "monitor-source-events",
      "style": {
        "position": "relative"
      }
    }}>{`Monitor Source Events`}<a parentName="h2" {...{
        "href": "#monitor-source-events",
        "aria-label": "monitor source events permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Each Source that receives Events has a live Event Debugger which will show you Events that are flowing
into the Source. The Event Debugger updates every 5 seconds, and may be paused using the Live/Pause toggle above the
list of Events.`}</p>
    <h2 {...{
      "id": "setup-the-source",
      "style": {
        "position": "relative"
      }
    }}>{`Setup the Source`}<a parentName="h2" {...{
        "href": "#setup-the-source",
        "aria-label": "setup the source permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Some sources have instructions on how to configure the Source, such as how to install tracking code on a website when
using the JavaScript Source. These Sources provide a Setup tab with instructions on how to move forward.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      